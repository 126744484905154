$(document).ready(function() {

  var TABLE_SEL = '.ada-table__block-wrapper';
  var TMPL_DESKTOP_CLASS = 'ada-table__row-template-desktop';
  var TMPL_MOBILE_CLASS = 'ada-table__row-template-mobile';
  var TMPL_DESKTOP_SEL = '.' + TMPL_DESKTOP_CLASS;
  var TMPL_MOBILE_SEL = '.' + TMPL_MOBILE_CLASS;
  var LOAD_MORE_SEL = '#loadMoreRows';
  var ITEMS_PER_PAGE = 10;


  if( !document.querySelector(TABLE_SEL) ) {
    return null;
  }

  if( !document.querySelector(TMPL_DESKTOP_SEL) || !document.querySelector(TMPL_MOBILE_SEL) ) {
    throw new Error('Can\'t find templates');
  }


  var table = $(TABLE_SEL)[0];
  var $table = $(table);
  var loadMoreBtn = $(LOAD_MORE_SEL);
  var tmplDesktop = $table.find(TMPL_DESKTOP_SEL).clone();
  var tmplMobile = $table.find(TMPL_MOBILE_SEL).clone();


  tmplDesktop.removeClass(TMPL_DESKTOP_CLASS);
  tmplMobile.removeClass(TMPL_MOBILE_CLASS);

  // rimuove i template dal DOM
  $table.find(TMPL_DESKTOP_SEL).remove();
  $table.find(TMPL_MOBILE_SEL).remove();



  function loadMoreRows() {
    var jsonStr = document.querySelector('script[type="application/ld+json"]').textContent;
    var json = JSON.parse(jsonStr);

    var loadedAttr = table.dataset.loaded;
    var loaded = loadedAttr === undefined ? 0 : parseInt(loadedAttr);
    var hasMore = true;

    for( var i=0; i<ITEMS_PER_PAGE; i++ ) {
      var j = loaded + i;
      if( j >= json.length ) {
        hasMore = false;
        break;
      }
      $table.append( getHtmlRow(tmplDesktop.clone(), json[j]) );
      $table.append( getHtmlRow(tmplMobile.clone(), json[j]) );
    }

    if( !hasMore ) {
      $(loadMoreBtn).remove();
    }

    table.dataset.loaded = loaded + i;

    colorRows();
    adaInitTooltipster();
    accordionListTable();
  }

  


  function getHtmlRow(template, data) {
    // setta i contenuti
    var placeholders = $(template[0]).find('[data-placeholdercontent]');
    for( var i=0; i<placeholders.length; i++ ) {
      var name = placeholders[i].dataset.placeholdercontent;
      if( data.hasOwnProperty(name) ) {
        $(placeholders[i]).html( data[name] );
      }
    }

    // setta ID e name dei checkbox
    var placeholdersId = $(template[0]).find('[data-placeholderid]');
    for( var i=0; i<placeholdersId.length; i++ ) {
      var attrId = placeholdersId[i].dataset.placeholderid;
      var keyId = placeholdersId[i].dataset.placeholdervalue;
      if( data.hasOwnProperty(keyId) ) {
        var value = data[keyId];
        $(placeholdersId[i]).attr( attrId, value );
      }
    }

    // setta i checkbox selezionati
    var placeholdersCheck = $(template[0]).find('[data-placeholderchecked]');
    for( var i=0; i<placeholdersCheck.length; i++ ) {
      var key = placeholdersCheck[i].dataset.placeholderchecked;
      if( data.hasOwnProperty(key) ) {
        var value = data[key];
        if( key === 'firma' && value === 'con riserva') {
          $(placeholdersCheck[i]).attr( 'checked', 'checked' );
        }
      }
    }

    

    // setta gli attributi
    var placeholdersAttrs = $(template[0]).find('[data-placeholderattr]');
    for( var j=0; j<placeholdersAttrs.length; j++ ) {
      var attrName = placeholdersAttrs[j].dataset.placeholderattr;
      var key = placeholdersAttrs[j].dataset.placeholderkey;
      if( data.hasOwnProperty(key) ) {
        var value = data[key];

        if( key === 'status' && value != 'Disattivato' ) {
          var statusClass = getStatusClass(value);
          $(placeholdersAttrs[j]).css('display', 'block');
          $(placeholdersAttrs[j]).removeAttr('class').attr('class', 'status-'+statusClass + ' tooltipster-'+statusClass);
        }

        if( key === 'tipo' ) {
          $(placeholdersAttrs[j]).html( getIcon(value, 'border') );
        }

        if( key === 'stato' ) {
          $(placeholdersAttrs[j]).html( getIcon(value, '') );
        }

        if( key === 'segnalazione' ) {
          if( value === 'Segnalazione' ) {
            $(placeholdersAttrs[j]).html( getIcon(value, '') );
          }
        }

        if( key === 'contestazione' ) {
          $(placeholdersAttrs[j]).html( getIcon(value, '') );
        }

        if( key === 'documenti_collegati' ) {
          $(placeholdersAttrs[j]).html( getIcon(value, '') );
        }

        if( key === 'nota_credito' ) {
          if( value === 'Nota di credito' ) {
            $(placeholdersAttrs[j]).html( getIcon(value, 'border') );
          }
        }

        if( key === 'firmato' ) {
          $(placeholdersAttrs[j]).html( getIcon(value, '') );
        }

        if( key === 'riserva' ) {
          $(placeholdersAttrs[j]).attr( attrName, value );
        }

        $(placeholdersAttrs[j]).attr( attrName, value );

      }
    }

    return template[0];
  }


  function getStatusClass(value) {
    var statusClass = 'gold';
    var status = value.toLowerCase().trim();
    if( status === 'revocato' ) {
      statusClass = 'blue';
    }

    if( status === 'non inviato' ) {
      statusClass = 'red';
    }
    return statusClass;
  }


  function getIcon(value, classes) {
    var icon;
    switch (value) {
      case 'Nuovo':
        icon = 'new';
        break;
      case 'Lancio':
        icon = 'launch';
        break;
      case 'Riassortimento':
        icon = 'restocking';
        break;
      case 'Differita':
        icon = 'delayed';
        break;
      case 'Promo':
        icon = 'promo';
        break;
      case 'In preparazione':
        icon = 'under-preparation';
        break;
      case 'In gestione':
        icon = 'under-management';
        break;
      case 'Consegnato':
        icon = 'delivered';
        break;
      case 'Annullato':
        icon = 'cancelled';
        break;
      case 'Parzialmente spedito':
        icon = 'partly-shipped';
        break;
      case 'Spedito':
        icon = 'shipped';
        break;
      case 'In giacenza':
        icon = 'in-stock';
        break;
      case 'Segnalazione':
        icon = 'mail';
        break;
      case 'Contestazione':
        icon = 'claim';
        break;
      case 'Documento':
        icon = 'check';
        break;
      case 'Nota di credito':
        icon = 'credit-note';
        break;
      case 'Completato':
        icon = 'check';
        break;
      case 'In corso':
        icon = 'progress';
        break;
      case 'Nessuno':
        icon = 'none';
        break;
      case 'In eccesso':
        icon = 'excess';
        break;
      case 'Omaggio':
        icon = 'gift';
        break;
      case 'Con riserva':
        icon = 'checked';
        break;
      case 'Senza riserva':
        icon = 'unchecked';
        break;
      default:
        icon = '';
    }
    return '<i class="adacto-icon adacto-icon-'+icon+' '+classes+'"></i>';
  }


  /* Righe con sfondo di colore alternato */
  function colorRows() {
    $(TABLE_SEL).each(function() {
      $('.ada-table__row-desktop').removeClass('odd');
      $('.ada-table__row-desktop:even').addClass('odd');
    });

    $(TABLE_SEL).each(function() {
      $('.accordion-mobile').removeClass('odd');
      $('.accordion-mobile:even').addClass('odd');
    });
  }


  /* Accordion Tabelle - Mobile */

  function accordionListTable() {
    var acc = $(".accordionMobile .more-less");    
    var i;
  
    for (i = 0; i < acc.length; i++) {
      acc[i].removeEventListener("click", toggleAccordion);
      acc[i].addEventListener("click", toggleAccordion);
    }
  }


  function toggleAccordion(e) {
    if( $(e.target).hasClass('tooltipstered') || $(e.target).parent().hasClass('tooltipstered') ) {
        return;
    }

    var item = this.closest('.accordionMobile');
    
    $(item).toggleClass("active");

    if ($(item).find(".more-less.adacto-icon").hasClass("more-less adacto-icon adacto-icon-minus")) {
        $(item).find(".more-less.adacto-icon").attr("class", "more-less adacto-icon adacto-icon-plus");
    }

    else if ($(item).find(".more-less.adacto-icon").hasClass("more-less adacto-icon adacto-icon-plus")) {
        $(item).find(".more-less.adacto-icon").attr("class", "more-less adacto-icon adacto-icon-minus");
    }

    var panel = $(item).next()[0];
    if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
    } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }


  loadMoreBtn.on('click', function (e) {
    e.preventDefault();
    loadMoreRows();
  });
  
  loadMoreRows();

  
  /* Ordinamento ascendente / discendente sulle colonne delle tabelle attivato al click sui div della prima riga */

  window.toggleOrder = function(event){
    var currentID = $(event.currentTarget).attr('id');
    table.dataset.orderby = currentID;
    table.dataset.order = (table.dataset.order === 'asc') ? 'desc' : 'asc';    
    console.log(table.dataset.orderby + " - " + table.dataset.order);
  } 


  $('.ada-table__th a').on( "click",function(e){
    window.toggleOrder(e);
  })
   

  
});
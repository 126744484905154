require('./app/polyfill');
require('./app/tooltipster');
require('./app/tables');

var mySwiper = undefined;
var mySwiperFullSlider = undefined;

/* Slider blocchi pagina login - mobile */
function blocksSwiper() {
    var screenWidth = $(window).width();

    if($('.ada-login-blocks .swiper-container').length == 0){
        return false;
    }
    else{
    if (screenWidth < 767 && mySwiper === undefined) {
        mySwiper = new Swiper('.ada-login-blocks .swiper-container', {
            direction: 'horizontal',
            loop: false,
            slidesPerView: 'auto',
            spaceBetween: 0,
            pagination: {
                el: '.ada-login-blocks .swiper-pagination',
                clickable: true
            }
        });
       

    } else if (screenWidth > 768 && mySwiper !== undefined) {
        try {
            mySwiper.destroy();
        } catch (e) {
        }
        mySwiper = undefined;
        jQuery('.swiper-wrapper').removeAttr('style');
        jQuery('.swiper-slide').removeAttr('style');
        
    }
}
}


/* Slider blocchi pagina home - mobile */
function blocksFeaturesSwiper() {
    var screenWidth = $(window).width();

    if($('.ada-blocks-features .swiper-container').length == 0){
        return false;
    }
    else{
    if (screenWidth < 767 && mySwiper === undefined) {
        mySwiper = new Swiper('.ada-blocks-features .swiper-container', {
            direction: 'horizontal',
            loop: false,
            slidesPerView: 'auto',
            spaceBetween: 0
        });
       

    } else if (screenWidth > 768 && mySwiper !== undefined) {
        try {
            mySwiper.destroy();
        } catch (e) {
        }
        mySwiper = undefined;
        jQuery('.swiper-wrapper').removeAttr('style');
        jQuery('.swiper-slide').removeAttr('style');
    }
}
}

/* Slider home */
function fullSlider() {
    mySwiperFullSlider = new Swiper('.ada-full-slider .swiper-container', {
        direction: 'horizontal',
        slidesPerView: 'auto',
        spaceBetween: 0,
        pagination: {
            el: '.ada-full-slider .swiper-pagination',
            clickable: true
        }
    });
}


/* Menu */
function menu() {
    var overlay = $('div.ada-navigator-overlay');
    var submenu = $('.ada-header__submenu');
    var navigator = $('nav.ada-navigator');
    var iconClose = $('.ada-header__close-icon');

    $('.ada-header__open-icon').on('click', function() {
        console.log('open');
       overlay.removeClass('ada-navigator-overlay--visible');

        submenu.addClass('ada-header__submenu--open');
        if (submenu.hasClass('ada-header__submenu--open')) {
            overlay.addClass('ada-navigator-overlay--visible');
            overlay.css("display", 'block');
            $('body').addClass('overflow-menu');
            
        }

        $('.ada-header__close-icon').click(function() {
            submenu.removeClass('ada-header__submenu--open');
            overlay.removeClass('ada-navigator-overlay--visible');
            overlay.css("display", 'none');
            $('body').removeClass('overflow-menu');
        });

        overlay.click(function() {
            submenu.removeClass('ada-header__submenu--open');
            overlay.removeClass('ada-navigator-overlay--visible');
            overlay.css("display", 'none');
            $('body').removeClass('overflow-menu');

        });
        return false;
    });


    $(function(){
        var collapsible = $(".ada-navigator__menu-content .collapse");

        $(".ada-navigator__menu-content .trigger").on("click",function(e){
            var currentMenu = $(this).parent().next();
            currentMenu.collapse("toggle");
        });

        collapsible.on("show.bs.collapse", function(e) {
            if( $(this).is(e.target) ) {
                var icon = getIcon(this);
                icon.removeClass("adacto-icon-plus-thick").addClass("adacto-icon-minus-thick");
            }
        });

        collapsible.on("hidden.bs.collapse", function(e) {
            if( $(this).is(e.target) ) {
                var icon = getIcon(this);
                icon.removeClass("adacto-icon-minus-thick").addClass("adacto-icon-plus-thick");
                closeChilds(this);
            }
        });

        function getIcon(item) {
            return $(item).parent().children(".ada-navigator__menu-item-heading").find(".ada-navigator__menu-icon i");
        }

        function closeChilds(item) {
            $(item).find(".collapse").collapse("hide");
        }

    });

}


/* Advanced Search - Datepicker - Controllo data selezionata */
function restrictPeriodDateAB() {
    var arrivalDate = $('#periodo-a').datepicker('getDate');
    var arrivalDateYear = $.datepicker.formatDate('yy', arrivalDate);
    var arrivalDateMonth = $.datepicker.formatDate('m', arrivalDate);
    var arrivalDateDay = parseFloat($.datepicker.formatDate('d', arrivalDate));

    $('#periodo-b').datepicker('option', 'minDate', new Date(arrivalDateYear, arrivalDateMonth - 1, arrivalDateDay + 1));
}

function restrictPeriodDateCD() {
    var arrivalDate = $('#periodo-c').datepicker('getDate');
    var arrivalDateYear = $.datepicker.formatDate('yy', arrivalDate);
    var arrivalDateMonth = $.datepicker.formatDate('m', arrivalDate);
    var arrivalDateDay = parseFloat($.datepicker.formatDate('d', arrivalDate));

    $('#periodo-d').datepicker('option', 'minDate', new Date(arrivalDateYear, arrivalDateMonth - 1, arrivalDateDay + 1));
}



/* Accordion Recap Mobile - Tabella Contabilità */
function accordionRecap() {
    var acc = document.getElementsByClassName("accordionMobileRecap");
    var target = $(".accordionMobileRecap");
    var i;

    for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function (e) {

            if( $(e.target).hasClass('tooltipstered') || $(e.target).parent().hasClass('tooltipstered') ) {
                return;
            }

            this.classList.toggle("active");

            if ($(this).find(".more-less.adacto-icon").hasClass("more-less adacto-icon adacto-icon-minus")) {
                $(this).find(".more-less.adacto-icon").attr("class", "more-less adacto-icon adacto-icon-plus");
            }

            else if ($(this).find(".more-less.adacto-icon").hasClass("more-less adacto-icon adacto-icon-plus")) {
                $(this).find(".more-less.adacto-icon").attr("class", "more-less adacto-icon adacto-icon-minus");
            }

            var panel = this.nextElementSibling;
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {

                panel.style.maxHeight = panel.scrollHeight + "px";
            }

            e.preventDefault();
        });
    }
}


/* Advanced search - Attiva e disattiva icona  */
function advancedSearch() {
    function toggleIcon(e) {
        $(e.target)
            .prev('.ada-advanced-search .panel-heading')
            .find('.more-less')
            .toggleClass('adacto-icon-plus adacto-icon-close');
    }

    $('.ada-advanced-search .panel-group').on('hidden.bs.collapse shown.bs.collapse', toggleIcon);
}


/* Accordion FAQs */
function faqToggle() {
    function toggleIcon(e) {
        $(e.target)
            .prev('.ada-faq .panel-heading')
            .find('.more-less')
            .toggleClass('adacto-icon-plus adacto-icon-minus');
    }

    $('.ada-faq .panel-group').on('hidden.bs.collapse shown.bs.collapse', toggleIcon);
}

/* Accordion */
function accordion() {
    function toggleIcon(e) {
        $(e.target)
            .prev('.ada-accordion .panel-heading')
            .find('.more-less')
            .toggleClass('adacto-icon-plus adacto-icon-minus');
    }

    $('.ada-accordion .panel-group').on('hidden.bs.collapse shown.bs.collapse', toggleIcon);
}
    
/* Load more News */
function loadmoreNews() {
    $("#loadMore").on('click', function (e) {
        e.preventDefault();
        $(".ada-news-list-item:hidden").slice(0, 5).slideDown();
        if ($(".ada-news-list-item:hidden").length == 0) {
            $("#loadMore").fadeOut('slow');
        } 
    });
}

/* Load more FAQs */
function loadmoreFaqs() {
    $("#loadMorefaqs").on('click', function (e) {
        e.preventDefault();
        $(".ada-faq__item:hidden").slice(0, 5).slideDown();
        if ($(".ada-faq__item:hidden").length == 0) {
            $("#loadMorefaqs").fadeOut('slow');
        } 
    });
}

/* Load more Listino prezzi */
function loadmorePriceslist() {
    $("#loadMorePriceslist").on('click', function (e) {
        e.preventDefault();
        $(".ada-price-list__item:hidden").slice(0, 5).slideDown();
        if ($(".ada-price-list__item:hidden").length == 0) {
            $("#loadMorePriceslist").fadeOut('slow');
        } 
    });
}


// Profilo - Attivazione pulsante "Modifica"
function editProfile() {
    var btn = $('#edit-profile');
    var summary = $('.ada-profile__summary');
    var inputs = $('.ada-profile-summary__form input[type="text"], .ada-profile-summary__form input[type="password"]');

    $(inputs).prop('readonly', true);
    var lang = $(btn).attr('data-language');
    var original_text = $(btn).attr('data-value');

    $(btn).on('click', function (e) {
        var translation;
        switch(lang) {
            case 'it':
                translation = "Salva";
                break;
            case 'en':
                translation = "Save";
                break;
            default:
                translation = "Save";
        }

        $(summary).toggleClass('active');

        var elementClicked = true;
        if (elementClicked = true && $(summary).hasClass('active')) {
            $(inputs).prop('readonly', false);
            $(this).text(translation);
            
        } else {
            $(inputs).prop('readonly', true);
            $(this).text(original_text);
        }
        e.preventDefault();
    });
}


// Wizard Segnalazioni - Attivazione elemento selezionato
function selectItem() {
    $('.ada-claim__wizard .ada-claim__item').each(function(){
        var t = $(this);
        t.find('a').click(function(){
            $('.ada-claim__item.active').removeClass('active');
            t.addClass('active');
            $('#next').removeClass('disabled');
        });
            
    });
}


// Wizard Segnalazioni Step 4 - Attivazione e disattivazione checkbox
function checkedColumn() {
    var checkbox = $("#activateCheck");
    var list = '.checkboxcell input[type="checkbox"]';
    $(checkbox).change(function() { 
        if (checkbox.is(":checked")){
            $(list).prop("checked", true);
        }  else {
            $(list).prop("checked", false);
        }
    });    
}


$(document).ready(function () {
    blocksSwiper();
	blocksFeaturesSwiper();
    fullSlider();
    menu();
    accordionRecap();
    advancedSearch();
    faqToggle();

    loadmoreNews();
    loadmoreFaqs();
    loadmorePriceslist();
    accordion();
    selectItem();
    checkedColumn();
    editProfile();

    /* select2 - Select personalizzate */
    $('.select2-item').each(function () {
        var placeholder = $(this).attr('data-placeholder') || '';
        $(this).select2({
            placeholder: placeholder,
            allowClear: false,
            minimumResultsForSearch: Infinity
        });
    });

    /* Advanced Search - data gruppo 1 */
    $('#periodo-a').datepicker({
        dateFormat: 'dd/mm/yy',
        minDate: 0,
        defaultDate: new Date(),
        onSelect: function () {
            $(this).trigger("change");
        },
    });
    $("#periodo-a").datepicker("setDate", new Date());


    $('#periodo-b').datepicker({
        dateFormat: 'dd/mm/yy',
        beforeShow: restrictPeriodDateAB,
        onSelect: function () {
            $(this).trigger("change");
        },
    });
    $("#periodo-b").datepicker("setDate", new Date(new Date().getTime() + 24 * 60 * 60 * 1000));


    /* Advanced Search - data gruppo 2 */
    $('#periodo-c').datepicker({
        dateFormat: 'dd/mm/yy',
        minDate: 0,
        defaultDate: new Date(),
        onSelect: function () {
            $(this).trigger("change");
        },
    });
    $("#periodo-c").datepicker("setDate", new Date());


    $('#periodo-d').datepicker({
        dateFormat: 'dd/mm/yy',
        beforeShow: restrictPeriodDateCD,
        onSelect: function () {
            $(this).trigger("change");
        },
    });
    $("#periodo-d").datepicker("setDate", new Date(new Date().getTime() + 24 * 60 * 60 * 1000));


});

$(window).resize(function () {
    blocksSwiper();
    blocksFeaturesSwiper();
});
window.adaInitTooltipster = function() {

  var themes = ['tooltipster-gold', 'tooltipster-blue', 'tooltipster-red'];
  var options = {
    trigger: 'custom',
    triggerOpen: {
      click: true,
      mouseenter: true,
      tap: true
    },
    triggerClose: {
      mouseleave: true,
      click: true,
      tap: true
    },
    animation: 'fade',
    contentAsHTML: true,
    interactive: true
  };

  for( var i=0; i<themes.length; i++ ) {
    var t = themes[i];
    var opts = Object.assign({}, options, {theme: t});
    $('.'+t+':not(.tooltipstered)').tooltipster(opts);
  }

};

